import React, { useState } from 'react';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot, SegmentedControl, List, Title, Blockquote, Placeholder, Switch, Text, Input} from '@telegram-apps/telegram-ui';

const models = [ 'GPT-3.5', 'GPT-4' ]; // Пример списка моделей
const modelsImage = ['DALL·E 3', 'DALL·E 2']; // Пример списка моделей
const messageStyle = ['Стандарт', 'ANTI GPT', 'PRO']; // Пример списка моделей

const Miniapp = () => {
    const [selectedModel, setSelectedModel] = useState(models[0]); // Выбранная модель
    const [selectedModelImage, setSelectedModelImage] = useState(modelsImage[0]); // Выбранная модель
    const [selectedMessageStyle, setMessageStyle] = useState(messageStyle[0]); // Выбранная модель

    const handleModelChange = (model) => {
        setSelectedModel(model); // Обновление выбранной модели
    };
    const handleModelImageChange = (model) => {
        setSelectedModelImage(model); // Обновление выбранной модели
    };
    const handleMessageStyleChange = (model) => {
        setMessageStyle(model); // Обновление выбранной модели
    };

    return (
        <AppRoot>
            <List style={{background: 'var(--tgui--secondary_bg_color)'}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Title
                        level="2"
                        weight="1"
                    >
                        Настройка модели ChatGPT
                    </Title>
                </div>
                <br/>
                <SegmentedControl>
                    {models.map((model, index) => (
                        <SegmentedControl.Item
                            key={index}
                            selected={selectedModel === model}
                            onClick={() => handleModelChange(model)}
                        >
                            {model}
                        </SegmentedControl.Item>
                    ))}
                </SegmentedControl>
                <br />
                <Blockquote type="text">
                    Чем лучше модель тем она умнее и может более точно обрабатывать сложные инструкции.
                </Blockquote>
                <br/>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Title
                        level="2"
                        weight="1"
                    >
                        Настройка модели изображния
                    </Title>
                </div>
                <br/>
                <SegmentedControl>
                    {modelsImage.map((model, index) => (
                        <SegmentedControl.Item
                            key={index}
                            selected={selectedModelImage === model}
                            onClick={() => handleModelImageChange(model)}
                        >
                            {model}
                        </SegmentedControl.Item>
                    ))}
                </SegmentedControl>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Title
                        level="2"
                        weight="1"
                    >
                        Информация с картинки
                    </Title>
                </div>
                <br />
                <Blockquote type="text">
                    Когда вы включаете эту функцию, вы можете отправить боту картинку, например, контрольную работу, и он сможет найти ответы на вопросы, основываясь на содержимом изображения.
                </Blockquote>
                <Placeholder description="Данная функция доступна только для пользователей с подпиской">                   <br/>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Text weight="1">
                            Информация с картинки
                        </Text>
                        <Switch />
                        <br />
                    </div>
                </Placeholder>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Title
                        level="2"
                        weight="1"
                    >
                        Общие настройки
                    </Title>
                </div>
                <br/>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text weight="1">
                        Стиль общения
                    </Text>
                </div>
                <br/>   
                <SegmentedControl>
                    {messageStyle.map((model, index) => (
                        <SegmentedControl.Item
                            key={index}
                            selected={selectedMessageStyle === model}
                            onClick={() => handleMessageStyleChange(model)}
                        >
                            {model}
                        </SegmentedControl.Item>
                    ))}
                </SegmentedControl>
                {selectedMessageStyle === 'Персональная' && (
                    <Input header="Введите промт" placeholder="Промт не должен быть пустым" />
                )}
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Text weight="1">
                        Получение рассылки
                    </Text>
                    <Switch />
                    <br />
                </div>
                <br/>
            </List>
 
        </AppRoot>
    );
};

export default Miniapp;
