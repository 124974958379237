// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Импортируем стили

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404</h1>
        <h2>Упс! Страница не найдена</h2>
        <p>Похоже, что вы зашли не туда. Давайте вернемся на главную!</p>
        <Link to="/" className="back-home-btn">Вернуться на главную</Link>
      </div>
    </div>
  );
};

export default NotFound;
